import React from 'react';
import {Button, PageHeader, Space, Table} from 'antd';
import * as apis from '../../api'

class Index extends React.Component {
    state = {
        type:'B',
      search:{
        page:1,
        page_size:10,
        id:"",
        fashion_name:"",
        production_status: 2,
        factory_id:"",
        user_id:"-",
      },
      loading:false,
      listresponse:{cusers:[],base:{list:[],page:1,page_size:10,total:0}}
    };

    requestFuncMap ={
        // 获取b端订单数据
        B:async function (search) {
            return await apis.getFactoryFromOrderOfBSideList(search);
        },
        // 获取c端订单数据
        C:async function (search) {
            return await apis.get_min_iapp_order_factory_list(search)
        }
    }


    async loadData(){
      const uinfo =sessionStorage.getItem("uinfo")
      let useinfo = JSON.parse(uinfo)
      let uid = useinfo.user.id
      let useinfos = await apis.getUserById(uid)
      this.setState({search:{...this.state.search,factory_id:useinfos.factory_id}})
      let { search ,type} = this.state
      const response =await (this.requestFuncMap)[type](search)
        if(response.total<= 0){
            this.setState({listresponse:{cusers:[],base:{list:[],page:1,page_size:10,total:0}}})
            return
        }
      this.setState({listresponse:{base:{list:response.list,page:search.page,page_size:search.page_size,total:response.total}}})
    }

    async componentDidMount(){
      this.setState({loading:true})
      await this.loadData()
      this.setState({loading:false})
    }
    async updateuserId(orderId){
      const uinfo =sessionStorage.getItem("uinfo")
      let useinfo = JSON.parse(uinfo)
      let uid = useinfo.user.id
      await apis.updateEmpOrderUserId(orderId,uid)
      this.loadData()
    }

    render() {
        let columns = [
            {
                title: '编号',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: '款式名',
                key: 'fashion_name',
                dataIndex: 'fashion_name'
            },
            {
                title: '操作',
                key: 'action',
                dataIndex: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Button type="primary" loading={loading} size="large" onClick={()=>{
                            // this.updateuserId(record.id)
                            // .then(() => {
                            let {type} = this.state
                            window.location.href = `/work?order_id=${record.id}&type=${type}`
                            // })
                            //this.props.history.push(`/index?order_id=${record.id}`)
                            // .catch((error)=>{
                            //   console.log(error)
                            // })
                        }}>开始缝纫</Button>
                    </Space>
                ),
            }
        ]
        let {listresponse,loading,type} = this.state
        return (
            <div style={{ height: '100vh',width:"100vw", display: 'flex', overflow: 'auto',flexDirection: 'column' }}>
                <PageHeader
                    onBack={() => window.location.href="/login"}
                    title="返回登录"
                    subTitle="待缝纫列表"
                >
                    <div style={{display: 'flex', gap: '10px'}}>
                        <Button type={type === "B" ? "primary" : "default"}
                        onClick={()=>{
                            this.setState({loading:true,type: "B",search:{page:1,page_size:10,total:0}})
                            this.loadData()
                            this.setState({loading:false})

                        }}

                        >B端订单</Button>
                        <Button type={type === "C" ? "primary" : "default"}
                                onClick={()=>{
                                    this.setState({loading:true,type: "C",search:{page:1,page_size:10,total:0}})
                                    this.loadData()
                                    this.setState({loading:false})

                                }}>C端订单</Button>
                    </div>


                </PageHeader>
                <Table rowKey={`id`} scroll={{y:document.body.clientHeight - 192}} pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger:false,
                    total:listresponse.base.total,
                    current:listresponse.base.page,
                    pageSize:listresponse.base.page_size,
                    onChange:(page)=>{
                        let cpSearch = {...this.state.search}
                        cpSearch.page = page
                        this.setState({search:cpSearch},async ()=>{
                            this.setState({loading:true})
                            await this.loadData()
                            this.setState({loading:false})
                        })
                    }
                }} loading={loading} columns={columns} dataSource={listresponse.base.list} />
            </div>
        )
    }
}

export default Index