import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from '../pages/layout/App'
// import Index from '../pages/index'
import Login from '../pages/login'
import OrderList from '../pages/order'
import Work from "../pages/work";

const R = () => {
  return (<Router>
    <App>
      {/* <Route path="/index" component={Index} /> */}
      <Route path="/login" component={Login} />
      <Route path="/order" component={OrderList} />
      <Route path="/work" component={Work} />
    </App>
  </Router>)
}

export default R