import * as THREE from 'three/build/three.module.js';
import {load_hdr} from './load_rgbe'
function scene(){
    if(window.scene){
        return window.scene
    }
    window.scene = new THREE.Scene()
    window.ambientLight = new THREE.AmbientLight( 0xffffff, 0.5) // Bright white ambient light
    window.scene.add( window.ambientLight );

    window.dirLight1 = new THREE.DirectionalLight( 0xffffff, 0.5); // Bright white directional light
    window.dirLight1.position.set( 0, 0.5, 1 );
    window.scene.add( window.dirLight1 );

    window.dirLight2 = new THREE.DirectionalLight( 0xffffff, 0.5); // Bright white directional light
    window.dirLight2.position.set( 0, 0.5, -1 );
    window.scene.add( window.dirLight2 );

    window.dirLight3 = new THREE.DirectionalLight( 0xffffff, 0.5 );
    window.dirLight3.position.set( 0, -0.5, 0 );
    window.scene.add( window.dirLight3 );

    // window.dirLight3 = new THREE.DirectionalLight( 0xffffff, 0.5 );
    // window.dirLight3.position.set( 0, 0, -0.5 );
    // window.scene.add( window.dirLight3 );
    load_hdr( texture => {
        window.scene.background = texture;
        window.scene.environment = texture;
    })
    return window.scene
}

export default scene

