import * as THREE from 'three/build/three.module.js';
function load_hdr(cb){
    let is = [
        'https://files.ifok.cn/test_crafts/dark-s_px.jpg', 'https://files.ifok.cn/test_crafts/dark-s_nx.jpg',
        'https://files.ifok.cn/test_crafts/dark-s_py.jpg', 'https://files.ifok.cn/test_crafts/dark-s_ny.jpg',
        'https://files.ifok.cn/test_crafts/dark-s_pz.jpg', 'https://files.ifok.cn/test_crafts/dark-s_nz.jpg'
    ]
    new THREE.CubeTextureLoader().load( is, function ( cubeTexture ) {
        cubeTexture.encoding = THREE.sRGBEncoding;
        cb(cubeTexture)
    })
    // new RGBELoader().load('https://files.ifok.cn/test_crafts/royal_esplanade_1k.hdr', function ( texture ) {
    //     texture.mapping = THREE.EquirectangularReflectionMapping;
    //     texture.anisotropy = window.renderer.getMaxAnisotropy();
    //     cb(texture)
    // });
}

export {load_hdr}