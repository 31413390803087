import * as THREE from 'three/build/three.module.js';

function camera(){
    if(window.camera){
        return window.camera
    }
    window.camera = new THREE.PerspectiveCamera( 45, 1, 0.25, 2000 ); 
    return window.camera
}

export default camera