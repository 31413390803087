import * as THREE from 'three/build/three.module.js';
function getRenderFrame(){
    if(window.renderer){
        return window.renderer
    }
    const convas=document.getElementById("canvas3d")
    window.renderer = new THREE.WebGLRenderer({
        canvas: convas,
        antialias: true,
        autoClear: true,
        alpha: true
    });
    window.renderer.shadowMap.enabled = true;
    window.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    window.renderer.setPixelRatio(window.devicePixelRatio); // 提高清晰度
    // document.getElementById("canvas3d").appendChild( window.renderer.domElement );
    // window.renderer.setPixelRatio( window.devicePixelRatio );

    window.renderer.outputEncoding = THREE.sRGBEncoding;
    // // window.renderer.toneMapping = THREE.ReinhardToneMapping;
    // window.renderer.toneMappingExposure = 3;

    window.renderer.setSize( convas.width, convas.height );
    return window.renderer
}

function resizeRendererToDisplaySize(cdom){
    if(window.renderer){
        window.renderer.setSize(cdom.clientWidth,cdom.clientHeight,false)
    }
}

function canvasRenderResize(canvasDom){
    canvasDom.width = document.getElementById("middle3DContent").clientWidth
        //let h = document.getElementById("middle3DContent").clientHeight
    canvasDom.style.width = canvasDom.width + 'px'

    canvasDom.height = canvasDom.width
    canvasDom.style.height = `${canvasDom.width}px`
    resizeRendererToDisplaySize(canvasDom)
}

export {getRenderFrame,resizeRendererToDisplaySize,canvasRenderResize}

