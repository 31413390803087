import axios from './middleware'

export const get_order_detail = async (id) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/order/detail?id=${id}`)
    return data;
}
export const getDesignData = async (id) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/base_new/customer/design_data?id=${id}`)
    return data;
}

export const login = async (args) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/open/user/login`, args)
    return data;
}

export const getOrderList = async (args) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/order/list`, args)
    return data;
}

export const getOrderFactoryList = async (args) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/order/list/factory`, args)
    return data;
}

export const getOrderStatusList = async (ids) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/order/status/list?ids=${ids}`)
    return data;
}

export const moveLogisticsStatus = async (orderId, logisticsStatus) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/order/logistics_status/move?order_id=${orderId}&logistics_status=${logisticsStatus}`)
    return data;
}

export const moveProductionStatus = async (orderId, productionStatus) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/order/production_status/move?order_id=${orderId}&production_status=${productionStatus}`)
    return data;
}

export const getProductionMaterialList = async (orderId) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/order/production_material/list?order_id=${orderId}`)
    return data;
}
export const updateEmpOrderUserId = async (orderId, uid) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/order/update_emp_order/userId?order_id=${orderId}&uid=${uid}`)
    return data;
}
export const updateEmpOrderStatusUserId = async (orderId, status) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/order/update_emp_order/status/userId?order_id=${orderId}&status=${status}`)
    return data;
}
export const getUserById = async (id) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/user/message?id=${id}`)
    return data;
}

export const get_min_iapp_order_factory_list = async (args) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/m/mini_app/order/factory/list`, args)
    return data;
}

export const get_order_factory_get_order_info = async (args) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/m/mini_app/order/factory/get_order_info`, args)
    return data;
}

export const get_production_material = async (id) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/order/production_material/list?order_id=${id}`)
    return data;
}

export const get_fabric_json = async (url) => {
    let ret = await fetch(`${url}?${new Date().getTime()}`)
    return ret.json();
}

export const getFashionDetail = async (args) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/fashion/detail?id=${args}`)
    return data;
}

export const getOption = async () => {
    let ret = await fetch(`https://files.ifok.cn/options.json?${new Date().getTime()}`)
    return await ret.json()
}

export const updateFashionAlgorithmParam = async (args) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/fashion/algorithm_param/update`, args)
    return data;
}

export const getSewingMode = async (id, targetInnerPanel, targetOutPanel) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/base_new/fashion/Sewing/${id}?targetInnerPanel=${targetInnerPanel}&targetOutPanel=${targetOutPanel}`)
    return data;
}

export const getProcess = async (id, targetInnerPanel, targetOutPanel,type) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/base_new/fashion/process?fashion_id=${id}&targetInnerPanel=${targetInnerPanel}&targetOutPanel=${targetOutPanel}&type=${type}`)
    return data;
}

export const getFactoryFromOrderOfBSideList = async (args) => {
    let data = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/order/factory/order/list`, args)
    return data;
}




