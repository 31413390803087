import React from 'react'
import './style.scss'

class App extends React.Component{

  state = {
    path:"",
    headerTop:0,
    opennav:false,
    opensearch:false,
    searchTxt:""
  }

  componentDidMount(){
    if(window.location.pathname === "/" || window.location.pathname === ""){
      window.location.href = "/login"
      return
    }
    this.setState({path:window.location.pathname})
  }

  onSearch(event){
    var e = event || window.event;
    if (e && e.keyCode === 13) { //回车键的键值为13
      window.location.href = `/index?name=${this.state.searchTxt}`
    }
  }

  render() {
    return (
        <article>
          { this.props.children }
          <div style={{clear:"both"}}></div>
        </article>
    )
  }
}

export default App;
