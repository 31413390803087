import { message } from 'antd';
import axios from 'axios'

// http request 拦截器
axios.interceptors.request.use(
  config => {
    try {
      if(window.location.pathname === "/login" || window.location.pathname === ""){
        return config
      }
      const uinfo = JSON.parse(sessionStorage.getItem("uinfo"))
      if ( uinfo ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
        const { secret, user } = uinfo
        config.headers.Authorization = secret.token  //请求头加上token
        config.headers.Uid = user.id  
      }
      // config.headers.Authorization = getQueryStringByName("token")  //请求头加上token
      // config.headers.Uid = getQueryStringByName("uid")  
      return config
    } catch (error) {
      window.location.href = "/login"
      return
    }
  },
  err => {
    // Toast.info(JSON.stringify(err), 3);
    return Promise.reject(err)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    if(!response){
        // Toast.info('请求出现错误 !!!', 3);
        message.error("请求服务器出现错误")
        return Promise.reject("请求服务器出现错误")
    }
    // 对响应数据做点什么
    if(response.status !== 200){
        message.error("请求服务器出现错误")
        return Promise.reject("请求服务器出现错误")
    }
    if(response.data.code === 401){
      message.error(response.data.msg || "登录已失效")
      setTimeout(()=>{
        window.location.href = "/login"
      },1000)
      return Promise.reject(response.data.msg)
    }
    if(response.data.code !== 200){
        message.error(response.data.msg)
        return Promise.reject(response.data.msg)
    }
    return response.data.data || [];
}, function (error) {
    // 对响应错误做点什么
    //Toast.info('请求出现错误 !!!', 3);
    return Promise.reject(error);
});

export default axios