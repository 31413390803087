import { message, Spin } from 'antd'
import React from 'react'
import * as styles from './index.module.css'
import * as apis from '../../api'

class Index extends React.Component{
    state = {
        loading:false,
        designQrcode:{},
        name: "",
        password: ""
    }

    async componentDidMount(){
        window.$('.login_content').particleground({dotColor: '#E8DFE8',lineColor: '#133b88'});
        // document.body.style.background = 'rgb(24,24,24)'
        // let designQrcode = await apis.getDesignQrcode()
        // await this.loopScanResult(designQrcode.uuid)
        // this.setState({designQrcode})
    }

    componentWillUnmount(){
        cancelAnimationFrame(window.loginf)
    }

    async login(){
        let {name, password} = this.state
        if(!name || !password){
            message.error("请输入工号或密码")
            return
        }
        this.setState({loading: true})
        try {
            let uinfo = await apis.login({name,password})
            //console.log(uinfo)
            sessionStorage.setItem("uinfo",JSON.stringify(uinfo))
            window.location.href = '/order'
            //this.props.history.push('/order/list')
        } catch (e) {
            
        }
        this.setState({loading: false})
        //console.log(name,password)
    }

    render(){
        let {loading,name,password} = this.state
        return (
            <div className="login_content" style={{width:'100%',height: document.body.clientHeight,backgroundSize: 'cover', backgroundImage: 'url(https://files.ifok.cn/2021-09-08/1631068976889.jpg)',backgroundPosition: 'center center'}}>
                <Spin spinning={loading}>
                    <div className={styles.login}>
                        <div className={styles.login_title}>
                            <span>员工登录</span>
                        </div>
                        <div className={styles.login_fields}>
                            <div className={styles.login_fields__user}>
                                <div className={styles.icon}>
                                    <img alt="" style={{width: 20}} src='https://files.ifok.cn/2021-09-08/1631070277297.png' />
                                </div>
                                <input value={name} onChange={(e)=>{
                                    this.setState({name: e.target.value})
                                }} placeholder='工号' maxLength="16" type='text' autoComplete="off"/>
                            </div>
                            <div className={styles.login_fields__password}>
                                <div className={styles.icon}>
                                    <img alt="" style={{width: 20}} src='https://files.ifok.cn/2021-09-08/1631070336419.png' />
                                </div>
                                <input value={password} onChange={(e)=>{
                                    this.setState({password: e.target.value})
                                }} placeholder='密码' maxLength="16" type='password' autoComplete="off" />
                            </div>
                            <div className={styles.login_fields__submit} onClick={this.login.bind(this)}>
                                <input type='button' value='登录' />
                            </div>
                        </div>
                        <div className={styles.disclaimer}>
                            <p style={{color: "#D3D7F7"}}>M端管理系统</p>
                        </div>
                    </div>
                    <p className={styles.foo}>种花云服</p>
                </Spin>
            </div>
        )
    }

}


export default Index