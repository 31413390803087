import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import get_camera from './camera'
import gert_scene from './scene'
import {getRenderFrame} from "../work/render";
function controls() {
    if(window.controls){
        return window.controls
    }
    // 设置 renderer 的大小

    window.controls = new OrbitControls( get_camera(), getRenderFrame().domElement );
    loop_render()
    // window.controls.addEventListener('change', () => {
    //    loop_render()
    // }); // use if there is no animation loop
    window.controls.minDistance = 0;
    window.controls.maxDistance = 1000;
    window.controls.zoom = 12
    window.controls.enableDamping = true;
    // window.controls.target.set( 6.919353456371592, 54.87197817838932, -26.74151715731276 );
    window.controls.update();
    return window.controls
}

function loop_render(){
    if(window.controls){
        window.controls.target.set(0,window.controls.target.y, 0);
    }
    requestAnimationFrame(loop_render)
    getRenderFrame().render( gert_scene(), get_camera() );  
}

export default controls