import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import * as THREE from 'three/build/three.module.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import getScene from './scene'
import {getRenderFrame} from './render'
import get_camera from './camera'
import get_scene from './scene'
const loader = new OBJLoader()
function loadObj(res,path){

    const textureLoader = new THREE.TextureLoader()
    loader.load(path, objects => {
        window.objects_3d = objects
        getScene().add(objects)
       
        getRenderFrame().render( get_scene(), get_camera() );
        //模型包围盒
        let modelBox3 = new THREE.Box3();
        var meshBox3 = new THREE.Box3();
        modelBox3.expandByObject(objects);
         //计算模型的中心点坐标，这个为爆炸中心
        let modelWorldPs = new THREE.Vector3().addVectors(modelBox3.max, modelBox3.min).multiplyScalar(0.5);
        // objects.name = 'MyObj_s';
        objects.traverse((child) => {
            if (child.isMesh) {
                child.material = new THREE.MeshLambertMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide })
                const pattern = res.find(item => item.code === child.name)
                const texture = textureLoader.load(pattern.value)
                texture.encoding = THREE.sRGBEncoding;
                child.material.map = texture;
                child.material.colorSpace = 'srgb'
                if(!window.code_maps){
                    window.code_maps = {}
                    window.code_maps[child.name] = child
                }else{
                    window.code_maps[child.name] = child
                }
                meshBox3.setFromObject(child);                   
                //获取每个mesh的中心点，爆炸方向为爆炸中心点指向mesh中心点
                let worldPs = new THREE.Vector3().addVectors(meshBox3.max, meshBox3.min).multiplyScalar(0.5);
                if(isNaN(worldPs.x))return;
                //计算爆炸方向
                child.worldDir = new THREE.Vector3().subVectors(worldPs, modelWorldPs).normalize();
                //保存初始坐标
                child.userData.oldPs = child.getWorldPosition(new THREE.Vector3())
                window.ORG_MATERIAL_EMISSIVE_COLOR[child.name] = child.material.emissive.getHex()
            }
        })
        apply_scalar(0)
        setContent(objects)
    })
}

function loadObjForMeasure(path,onSuccess,onError){
    if(path === null || path===''){
        return
    }
    const scene = new THREE.Scene()
    const camera = get_camera()
    scene.clear()
    const renderer =getRenderFrame()
    scene.add( new THREE.AmbientLight( 0x443333 ) );
    const dirLight1 = new THREE.DirectionalLight( 0xffddcc, 1 );
    dirLight1.position.set( 1, 0.75, 0.5 );
    scene.add( dirLight1 );
    const dirLight2 = new THREE.DirectionalLight( 0xccccff, 1 );
    dirLight2.position.set( - 1, 0.75, - 0.5 );
    scene.add( dirLight2 );
    loader.load(path, object => {
        scene.add(object)
        object.updateMatrixWorld()
        object.traverse(child => {
            if (child instanceof THREE.Mesh) {
                child.material.transparent = true;
                child.material.opacity = 0.8;
            }
        });

        const box = new THREE.Box3().setFromObject(object);
        const center = box.getCenter(new THREE.Vector3());
        const offsetY = 85;
        object.position.y -= offsetY;
        // Set camera position and target
        camera.position.copy(center.clone().add(new THREE.Vector3(0, 0, 280)));
        camera.lookAt(0,0,0);

        // Adjust camera frustum
        const distance = center.distanceTo(camera.position);
        camera.near = distance / 10;
        camera.far = distance * 10;
        camera.updateProjectionMatrix();

        renderer.render(scene, camera);
        onSuccess(object);


        const controls = new OrbitControls(camera, renderer.domElement);
        controls.minPolarAngle = Math.PI / 2;
        controls.maxPolarAngle = Math.PI / 2;
        controls.update();

        // Render loop
        function animate() {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        }
        animate();
        onSuccess(object)
    }, xhr => {

    }, error => {
        onError(error)
    })

}


function addPoint(vs,ob){
    var geometry = new LineGeometry()
    var pointArr = []
    for(let i=0;i<vs.length;i++){
        for(let j=0;j<vs[i].length;j++){
            pointArr.push(vs[i][j])
        }
    }
    geometry.setPositions(pointArr)
    var material = new LineMaterial({
        color: 0xaa2116,
        linewidth: 10
    })
    material.resolution.set(document.body.clientWidth * 0.7, document.body.clientHeight)
    var line = new Line2(geometry, material)
    line.computeLineDistances()
    ob.add(line)
}

function clear_cutpart_color(){
    for(let code in window.code_maps){
        window.code_maps[code].material.emissive.setHex(window.ORG_MATERIAL_EMISSIVE_COLOR[code])
    }
}


function clear_points(){
    for(let code in window.code_maps){
        window.code_maps[code].children.length = 0
    }
}


function setContent(object) {
    let camera = get_camera()
    object.updateMatrixWorld();
    const box = new THREE.Box3().setFromObject(object);
    // const size = box.getSize(new THREE.Vector3()).length();
    const boxSize = box.getSize(new THREE.Vector3());
    const center = box.getCenter(new THREE.Vector3());
    object.position.x = object.position.x - center.x;
    object.position.y = object.position.y - center.y;//修改center.y可以设置模型整体上下偏移
    object.position.z = object.position.z - center.z;
    camera.position.copy(center);
    if (boxSize.x > boxSize.y) {
        camera.position.z = boxSize.x * 2.85;
    } else {
        camera.position.z = boxSize.y * 2.85;
    }
    camera.lookAt(0, 0, 0);
}

function apply_scalar(scalar) {
    window.objects_3d.traverse(function (value) {
        if(!value.isMesh || !value.worldDir) return;
        //爆炸公式
        value.position.copy(new THREE.Vector3().copy(value.userData.oldPs).add(new THREE.Vector3().copy(value.worldDir).multiplyScalar(scalar)))
    });
}

export {loadObj, addPoint, clear_points,clear_cutpart_color,apply_scalar,loadObjForMeasure}